// utils/retry.ts

interface RetryOptions<T> {
    maxAttempts?: number;
    delayMs?: number;
    shouldRetry?: (data: T) => boolean;
    onAttempt?: (attempt: number) => void;
}

export const retryFetch = async <T>(
    fetchFn: () => Promise<T>,
    { maxAttempts = 3, delayMs = 1000, shouldRetry = (data: T) => !data, onAttempt }: RetryOptions<T> = {}
): Promise<T> => {
    let attempts = 0

    while (attempts < maxAttempts) {
        try {
            const result = await fetchFn()

            if (!shouldRetry(result)) {
                return result
            }

            attempts++
            onAttempt?.(attempts)

            if (maxAttempts && attempts < maxAttempts) {
                await new Promise(resolve => setTimeout(resolve, delayMs))
            }
        } catch (error) {
            attempts++
            onAttempt?.(attempts)

            if (maxAttempts && attempts === maxAttempts) { throw error }
            await new Promise(resolve => setTimeout(resolve, delayMs))
        }
    }

    throw new Error(`Failed to fetch data after ${maxAttempts} attempts`)
}
